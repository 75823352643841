(function ($) {
	$(document).ready(function () {

		// menu script start
		$('<span class="menu-arrow"><i class="fa-solid fa-angle-down"></i></span>').insertAfter('.menu-item-has-children > a');
		$('#nav-icon1').click(function () {
			$(this).toggleClass('open');
			$('.main-menu').slideToggle();
			$("body").toggleClass("scrollhide");
		});
		$('.menu-arrow').click(function () {
			if ($(window).width() < 1199) {
				// $(this).toggleClass('arrow')
				$(this).parent('.menu-item-has-children ').find('.sub-menu').slideToggle();
				// $(this).parent('.menu-item-has-children ').find('.sub-menu').toggleClass('mobi-menu').siblings().removeClass('mobi-menu');
				$(this).parent('.menu-item-has-children ').siblings('.menu-item-has-children ').find('.sub-menu').slideUp();
				$(this).parent().toggleClass('arrow').siblings().removeClass('arrow');
				console.log('sub-menu');
			}
		});
	});

	  // Testimonial Slider 
	  $('.slider-container').each(function () {
		const $sliderWrapper = $(this).find('.slider-wrapper');
		const $slideCards = $sliderWrapper.find('.slideCard');
	
		// Clone the slides for continuous effect
		$slideCards.each(function () {
		  const $clone = $(this).clone();
		  $sliderWrapper.append($clone);
		});
	
		// Set total width and animation duration
		const totalWidth = $sliderWrapper[0].scrollWidth;
		$sliderWrapper.css({
		  'width': totalWidth + 'px',
		  'animation-duration': `${(totalWidth / 480) * 2}s`
		});
	
		// Reset animation after each iteration to create a seamless effect
		$sliderWrapper.on('animationiteration', function () {
		  $(this).css('animation', 'none');
		  this.offsetHeight; // Trigger reflow to reset animation
		  $(this).css('animation', '');
		});
	  });



	// map script
	// Make initialize a global function
	window.initialize = function () {
		var map, map2;
		var bounds = new google.maps.LatLngBounds();
		var bounds2 = new google.maps.LatLngBounds();
	
		var mapOptions = {
			mapTypeId: "roadmap",
		};
	
		var mapOptions2 = {
			mapTypeId: "roadmap",
		};
	
		// Initialize maps if respective containers are present
		if ($("#map_canvas").length) {
			map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
			map.setTilt(45);
		}
	
		if ($("#map_canvas2").length) {
			map2 = new google.maps.Map(document.getElementById("map_canvas2"), mapOptions2);
			map2.setTilt(45);
		}
	
		// Multiple Markers
		var markers = [
			["99 South County Rd 300 E Danville, IN 46122", 39.7609517856188, -86.46704959663803, "assets/images/red-pin.svg"],
			["1605 Graham Rd. Franklin, IN 46131", 39.49872239590509, -86.0541205328465, "assets/images/red-pin.svg"],
			["452 Grand Valley Blvd. Martinsville, IN 46151", 39.4174949832295, -86.40448586168621, "assets/images/red-pin.svg"],
			["5401 N State Road 39 Martinsville, IN 46151", 39.50112093516716, -86.45416070401072, "assets/images/red-pin.svg"],
			["6601 N US 31 Whiteland, IN 46184", 39.57378817002194, -86.09542783284265, "assets/images/red-pin.svg"],
		];
	
		// Info Window Content
		var infoWindowContent = [
			['<h3>99 South County Rd 300 E Danville, IN 46122</h3>'],
			['<h3>1605 Graham Rd. Franklin, IN 46131</h3>'],
			['<h3>452 Grand Valley Blvd. Martinsville, IN 46151</h3>'],
			['<h3>5401 N State Road 39 Martinsville, IN 46151</h3>'],
			['<h3>6601 N US 31 Whiteland, IN 46184</h3>'],
		];
	
		// Initialize InfoWindow
		var infoWindow = new google.maps.InfoWindow();
	
		// Add markers to the map
		markers.forEach(function (markerData, i) {
			var position = new google.maps.LatLng(markerData[1], markerData[2]);
			bounds.extend(position);
			bounds2.extend(position);
	
			// Create markers for both maps
			var markerOptions = {
				position: position,
				title: markerData[0],
				icon: {
					url: markerData[3], // Custom icon URL
					scaledSize: new google.maps.Size(40, 40), // Resize the icon if necessary
				},
			};
	
			if (map) {
				var marker = new google.maps.Marker({ ...markerOptions, map: map });
				google.maps.event.addListener(marker, "click", function () {
					infoWindow.setContent(infoWindowContent[i][0]);
					infoWindow.open(map, marker);
				});
			}
	
			if (map2) {
				var marker2 = new google.maps.Marker({ ...markerOptions, map: map2 });
				google.maps.event.addListener(marker2, "click", function () {
					infoWindow.setContent(infoWindowContent[i][0]);
					infoWindow.open(map2, marker2);
				});
			}
		});
	
		// Fit map bounds and set zoom
		if (map) {
			map.fitBounds(bounds);
			google.maps.event.addListenerOnce(map, "bounds_changed", function () {
				this.setZoom(9);
			});
		}
	
		if (map2) {
			map2.fitBounds(bounds2);
			google.maps.event.addListenerOnce(map2, "bounds_changed", function () {
				this.setZoom(9);
			});
		}
	};
	
	// Load Google Maps API and initialize
	jQuery(function ($) {
		var script = document.createElement("script");
		script.src =
			"https://maps.googleapis.com/maps/api/js?key=AIzaSyAryRO9Ng3KZ2XMJx8y-Ue_3ew5oZZ_cNo&callback=initialize";
		script.async = true;
		document.body.appendChild(script);
	});


	// $('select').niceSelect();
	  $(".list").niceScroll();
	  $('.xyz').on('click',function(){
		  
		  event();
	  });
	



})(jQuery);
